<template>
  <b-img
    fluid
    :src="imgUrl"
    alt="Logo"
  />
</template>
<script>
import {
  BImg,
} from 'bootstrap-vue'

export default ({
  components: {
    BImg,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      imgUrl: require('@/assets/images/logo/logo-small.png'),
    }
  },
})
</script>
